const largeImage = document.querySelector('.hz-image--large');
const smallImages = document.querySelectorAll('.hz-image--small .hz-image__inner');
let largeTop = 0;

const imagePlacement = {
    justifyImages() {
        smallImages.forEach((image) => {
            const imageParent = image.parentElement.parentElement;
            const hlSmall = imageParent.previousElementSibling;

            imageParent.parentElement.parentElement.style.justifyContent = 'flex-start';
            imageParent.parentElement.parentElement.style.zIndex = '5';

            const largeParent = largeImage.parentElement;
            largeTop = largeParent.offsetTop;

            imageParent.style.transform = `translateY(${largeTop}px)`;
            hlSmall.style.transform = `translateY(${largeTop}px)`;
        });
    },

    debounce(func, timeout = 600) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    },

    placeImages() {
        const largeParent = largeImage.parentElement;
        largeTop = largeParent.offsetTop;

        smallImages.forEach((image) => {
            const imageParent = image.parentElement.parentElement;
            const hlSmall = imageParent.previousElementSibling;

            imageParent.style.transform = `translateY(${largeTop}px)`;
            hlSmall.style.transform = `translateY(${largeTop}px)`;
        });
    },

    init() {
        if (smallImages.length > 0) {
            const topUpdate = this.debounce(() => this.placeImages());

            this.justifyImages();

            window.addEventListener('resize', topUpdate);
        }
    },
};

export default imagePlacement;

export function imagePlacementInit() {
    if (smallImages.length > 0) {
        const topUpdate = imagePlacement.debounce(() => imagePlacement.placeImages());

        imagePlacement.justifyImages();

        window.addEventListener('resize', topUpdate);
    }
}
