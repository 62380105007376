/* eslint-disable no-unused-vars */
// GSAP Imports
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

// Modal Import
import A11yDialog from 'a11y-dialog';

// Horizontal
import { horizontalInit } from '../components/horizontal-section';
import { imagePlacementInit } from '../components/image-placement';

// Navigation Vars
const menuElementsList = document.querySelectorAll(
    "[data-ref='menuContainer'], [data-ref='menuBanner'], [data-ref='menuBtn'], html",
);
const menuElements = Array.from(menuElementsList);
const navEl = document.querySelector("[data-ref='menuContainer']");
const infoHeader = document.querySelector("[data-ref='menuBanner']");
const navExit = document.querySelector("[data-ref='menuExit']");
const toggleEl = document.querySelector("[data-ref='menuBtn']");
const plainHero = document.querySelector("[data-ref='plain-hero']");
const openClassString = '--is-opened';
let menuOpen = false;

// GSAP Smooth Scroll Vars
let smoother;
const userReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
const scrollEl = document.getElementById('smooth-wrapper');
const disableScrollEls = document.querySelectorAll('.js-disable-scroller');

// Modals YT Logic
let heroPlayer;

function runYoutubeLogic(dialog) {
    const heroPlayerWrapper = document.getElementById('hero-yt-player');

    // eslint-disable-next-line no-undef
    heroPlayer = new YT.Player(heroPlayerWrapper.id, {
        height: '1080',
        width: '1920',
        enablejsapi: 1,
        videoId: heroPlayerWrapper.dataset.ytid,
        origin: window.location.origin,
        widget_referrer: window.location.href,
        playerVars: {
            showinfo: 0,
            modestbranding: 1,
            playsinline: 1,
            rel: 0,
        },
        events: {
            onStateChange(event) {
                if (event.data === window.YT.PlayerState.ENDED) {
                    dialog.hide();
                }
            },
            onError(error) {
                // eslint-disable-next-line no-console
                console.error('Failed to create Youtube video player', error);
            },
        },
    });
}

function pauseScroller(isModal = false) {
    if (smoother) smoother.paused(true);

    if (isModal) {
        menuElements.forEach((el) => {
            el.classList.add('--modal-open');
        });
    }
}

function resumeScroller(isModal = false) {
    if (smoother) smoother.paused(false);

    if (isModal) {
        menuElements.forEach((el) => {
            el.classList.remove('--modal-open');
        });
    }
}

// a11y Modals Logic
const modals = {
    listenCustomEvents() {
        // Listen for the custom events
        document.addEventListener('trModalPauseScroll', (e) => {
            pauseScroller(true);
        }, false);

        document.addEventListener('trModalPauseResume', (e) => {
            resumeScroller(true);
        }, false);
    },

    startHeroVideo() {
        // Hero Modal Video logic
        const heroModalEl = document.getElementById('hero_film');

        if (heroModalEl) {
            const heroDialog = new A11yDialog(heroModalEl);
            const checkYT = setInterval(() => {
                // eslint-disable-next-line no-undef
                if (YT.loaded) {
                    clearInterval(checkYT);
                    runYoutubeLogic(heroDialog);
                }
            }, 100);

            heroDialog.on('show', () => {
                pauseScroller(true);
                heroPlayer.playVideo();
            });

            heroDialog.on('hide', () => {
                resumeScroller(true);
                heroPlayer.stopVideo();
            });
        }
    },

    init() {
        this.listenCustomEvents();
        this.startHeroVideo();
    },
};

// Site Navigation Logic
const navigation = {
    transparentBackground() {
        infoHeader.classList.add('info-banner--transparent-bg');
    },

    closeNavMenu() {
        menuOpen = false;

        menuElements.forEach((el) => el.classList.remove(openClassString));

        navEl.ariaHidden = 'true';
        toggleEl.ariaExpanded = 'false';
        document.getElementById('menuText').textContent = 'MENU';

        document.querySelector('body').classList.remove('no-scroll');

        resumeScroller(false);
    },

    openNavMenu() {
        menuOpen = true;

        menuElements.forEach((el) => el.classList.add(openClassString));

        navEl.ariaHidden = 'false';
        toggleEl.ariaExpanded = 'true';
        navEl.scrollTop = 0;
        document.getElementById('menuText').textContent = 'CLOSE';

        document.querySelector('body').classList.add('no-scroll');

        pauseScroller(false);
    },

    bindMenuEvents() {
        toggleEl.addEventListener('click', () => {
            if (menuOpen && navEl.ariaHidden === 'false') {
                this.closeNavMenu();
            } else {
                this.openNavMenu();
            }
        });
        navExit.addEventListener('click', () => {
            if (menuOpen && navEl.ariaHidden === 'false') {
                this.closeNavMenu();
            }
        });
    },

    init() {
        if (plainHero) {
            this.transparentBackground();
        }

        if (toggleEl) {
            this.bindMenuEvents();
        }
    },
};

const siteAnimations = {
    pauseAutoplayVideos() {
        const videoList = document.querySelectorAll('video');

        videoList.forEach((vid) => {
            vid.pause();
        });
    },
    animateFooterLogo() {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-animate-footer',
                pin: true,
                start: 'bottom bottom',
                end: '+=500',
                scrub: 0.2,
                duration: {
                    min: 0.2,
                    max: 3,
                },
            },
        });

        tl.fromTo(
            '.svg-animated',
            {
                opacity: 0,
                stroke: 'white',
                drawSVG: '50% 50%',
            },
            {
                opacity: 1,
                stroke: 'transparent',
                drawSVG: '0',
            },
        );
    },

    animateHeroContent() {
        const tl = gsap.timeline({
            repeat: 0,
        });

        tl.fromTo(
            [
                '.hero__content',
                '.hero__map-container',
            ],
            {
                opacity: 0,
            },
            {
                duration: 2,
                opacity: 1,
            },
        );
    },

    siteParallax() {
        gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);

        if (!disableScrollEls.length) {
            smoother = ScrollSmoother.create({
                wrapper: '#smooth-wrapper',
                content: '#smooth-content',
                smooth: 1,
                effects: true,
                smoothTouch: 0.1,
                normalizeScroll: false,
                ignoreMobileResize: true,
            });
            ScrollTrigger.refresh();
        } else {
            smoother = false;
        }

        // Disable footer for now
        // animateFooterLogo();
        this.animateHeroContent();
    },

    runHorizontalSections() {
        // Horizontal Section animations
        const horizontalSectionAnimationsEls = document.querySelectorAll('.js-horizontal-animation');
        if (horizontalSectionAnimationsEls.length > 0) {
            horizontalInit();

            const smallImagePlacementEls = document.querySelectorAll('.hz-image--small');
            if (smallImagePlacementEls.length > 0) {
                imagePlacementInit();
                ScrollTrigger.refresh();
            }
        }
    },

    init() {
        if (!userReducedMotion && scrollEl) {
            // Good to go with all the animations
            this.siteParallax();
            this.runHorizontalSections();
        } else {
            // Preferring reduced motion, make sure the videos are paused
            this.pauseAutoplayVideos();
        }

        // The animations for these are handled in CSS so no JS disable needed
        modals.init();
        navigation.init();
    },
};

export default siteAnimations;
