/* eslint-disable no-console */
// Import our CSS
import '../styles/app.scss';

// Import the global JS
import 'core-js/stable'; // Polyfill js as needed for legacy
import 'regenerator-runtime/runtime'; // Recompile asyc as needed for legacy

// Scripts that need early loading before async
// Site Wide Parallax (needs early non-async load)
import siteAnimations from './scripts/site-animations-nav-modals';

// Add JS Loaded class to the body
document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js-loaded');

// Run the site-wide animations and nigation
siteAnimations.init();

// App main
const main = async () => {
    // Import Vendors
    await import('./_vendors');

    // Import Scripts
    await import('./_scripts');

    // Import Components
    await import('./_components');

    // Import Components
    await import('./_vue-components');
};

main()
    .then(() => {})
    .catch((e) => console.error(e));

// Vite HMR Watcher for local dev
// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    console.log('meta hot is true');

    import.meta.hot.accept(() => {
        console.log('HMR running');
    });
}
