// Note, this is script is now called from `site-aimations-nav-modal`
// This helps improve the load timing with the other scroll animations
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const horizontalSections = gsap.utils.toArray(document.querySelectorAll("[data-ref='horizontal-scroll']"));
const userReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

const horizontalSectionAnimations = {
    runAnimations() {
        horizontalSections.forEach((sideScroll) => {
            const sideAnimation = gsap.to(sideScroll, {
                x: () => -(sideScroll.scrollWidth - document.documentElement.clientWidth) + 'px',
                ease: 'none',
                scrollTrigger: {
                    anticipatePin: 1,
                    ease: 'none',
                    invalidateOnRefresh: true,
                    pin: true,
                    scrub: 0.1,
                    start: 'top top',
                    end: '+=4000',
                    trigger: sideScroll,
                },
            });

            const sideFade = gsap.utils.toArray(document.querySelectorAll("[data-ref='side-fade']"));

            function sideFadeAnimation(content) {
                content.forEach((block) => {
                    gsap.fromTo(
                        block,
                        {
                            autoAlpha: 0,
                            x: -75,
                        },
                        {
                            autoAlpha: 1,
                            duration: 1,
                            x: 0,

                            scrollTrigger: {
                                containerAnimation: sideAnimation,
                                once: false,
                                trigger: block,
                                scrub: true,
                                end: '-5',
                            },
                        },
                    );
                });
            }

            if (sideFade.length > 0 && !userReducedMotion) {
                sideFadeAnimation(sideFade);
            }
        });
    },
};

export function horizontalInit() {
    if (horizontalSections.length > 0) {
        horizontalSectionAnimations.runAnimations();
    }
}
